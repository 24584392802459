/* General styles */
body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #F2F2F2; /* Set the background color */
  color: #333; /* Set a darker color for the text for better contrast */
}

.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
}

.section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem; /* Adjust as needed */
  width: 100%;
  gap: 1rem; /* Add gap between text and image */
}

.text-container, .image-container {
  flex: 1;
  padding: 1rem;
}

.text-container3 {
  flex: 1;
  padding: 1rem;
}

.image-container img {
  max-width: 100%;
  height: auto;
  border-radius: 10px; /* Optional: to add some styling */
}

.buttons-container {
  margin-top: 1rem; /* Adjust as needed */
}

.buttons-container .home-btn {
  margin-right: 10px; /* Space between buttons */
}

/* Specific button styles for .home-btn */
.home-btn {
  display: inline-block;
  outline: 0;
  border: 0;
  cursor: pointer;
  background: #000000;
  color: #FFFFFF;
  border-radius: 8px;
  padding: 14px 24px 16px;
  font-size: 18px;
  font-weight: 700;
  line-height: 1;
  transition: transform 200ms, background 200ms;
  text-decoration: none; /* Remove underline */
}

.home-btn:hover {
  transform: translateY(-2px);
  color: #FFFFFF; /* Maintain the same color on hover */
}

/* Specific button styles for .home-btn1 */
.home-btn1 {
  display: inline-block;
  outline: 0;
  border: 0;
  cursor: pointer;
  border-radius: 8px;
  padding: 14px 24px 16px;
  font-size: 18px;
  font-weight: 700;
  line-height: 1;
  transition: transform 200ms, background 200ms;
  background: transparent;
  color: #000000;
  box-shadow: 0 0 0 3px #000000 inset;
  text-decoration: none; /* Remove underline */
}

.home-btn1:hover {
  transform: translateY(-2px);
  color: #000000; /* Maintain the same color on hover */
}

.map-section {
  position: relative;
  width: 100%;
  height: 500px;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.map-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 70%;
  height: 100%;
}

.map-description {
  position: absolute;
  top: 50%;
  right: 10%;
  transform: translateY(-50%);
  background-color: rgba(255, 255, 255, 0.9);
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
  width: 20%;
}

.map-description h2 {
  margin: 0;
  font-size: 24px;
}

.map-description p {
  margin: 10px 0;
}

.map-description a {
  display: inline-block;
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #000;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .section {
    flex-direction: column;
    align-items: center; /* Center-align the content on mobile */
  }

  .text-container, .image-container {
    width: 100%;
    padding: 1rem 0;
  }

  .image-container {
    margin-top: 1rem; /* Add margin above the image to separate from the text */
  }

  .text-container {
    order: 2; /* Ensure text is below the image */
  }

  .image-container {
    order: 1; /* Ensure image is above the text */
  }

  .map-section {
    flex-direction: column;
    height: auto;
  }

  .map-container {
    position: relative;
    width: 100%;
    height: 300px;
  }

  .map-description {
    position: relative;
    top: auto;
    right: auto;
    transform: none;
    width: 100%;
    height: auto;
    margin-top: 20px;
    padding: 10px;
  }

  .map-description h2 {
    font-size: 20px;
  }

  .map-description p {
    font-size: 14px;
  }

  .buttons-container .home-btn, .buttons-container .home-btn1 {
    padding: 10px 18px;
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .home-btn, .home-btn1 {
    width: 100%;
    text-align: center;
    padding: 12px 16px;
    font-size: 14px;
    margin-bottom: 10px;
  }

  .map-description {
    font-size: 14px;
  }
  
}
