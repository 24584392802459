/* General styles */
body {
  margin: 0;
  font-family: 'Arial', sans-serif;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #000000;
  padding: 1rem 2rem;
  box-shadow: 0 4px 6px rgba(33, 24, 116, 0.1);
}

.logo h1 {
  color: white;
  margin: 0;
  font-family: 'Dancing Script', cursive;
  font-size: 2rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

nav ul {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

nav ul li {
  margin-right: 1.5rem;
  position: relative;
}

nav ul li:last-child {
  margin-right: 0;
}

nav ul li a,
.dropdown-button {
  color: white;
  text-decoration: none;
  font-weight: bold;
  font-size: 1.1rem;
  transition: color 0.3s;
  display: flex;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
}

.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #ffffff;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  border: none;
  padding: 0;
  margin: 0;
  list-style: none;
  flex-direction: column;
  min-width: 160px;
  z-index: 1000;
}

.dropdown-menu.show {
  display: flex;
}

.dropdown-menu li {
  padding: 10px 20px;
}

.dropdown-menu li a {
  color: #333;
  text-decoration: none;
  display: block;
  width: 100%;
}

.dropdown:hover .dropdown-menu {
  display: flex;
}

.dropdown-button:focus {
  outline: none;
}

/* Side Menu Styles */
.side-menu {
  position: fixed;
  top: 0;
  left: -100%; /* Initially hidden */
  width: 100%;
  height: 100%;
  background: #000;
  color: white;
  padding: 2rem 1rem;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
  transition: left 0.3s ease;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.side-menu.show {
  left: 0; /* Show menu */
}

.side-menu .close-btn {
  position: absolute;
  top: 1rem;
  right: 1rem;
  color: white;
  font-size: 2rem;
  cursor: pointer;
}

.side-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
  text-align: center;
}

.side-menu ul li {
  margin: 1rem 0;
}

.side-menu ul li a {
  color: white;
  text-decoration: none;
  font-size: 1.5rem;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .header {
    justify-content: space-between;
  }

  .nav-links {
    display: none; /* Hide default nav links */
  }

  .menu-button {
    display: block; /* Show menu button on mobile */
    background: none;
    border: none;
    color: white;
    font-size: 1.5rem;
    cursor: pointer;
  }
}

@media (min-width: 769px) {
  .menu-button {
    display: none; /* Hide menu button on larger screens */
  }
}
