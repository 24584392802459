/* General Styling */
body {
  font-family: 'Helvetica', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f2f2f2;
  color: #333;
}

/* Main Content Styling */
.adulte-main {
  padding: 2rem;
  background-color: #f2f2f2;
}

.adulte-title {
  text-align: center;
  font-size: 3rem;
  margin-bottom: 2rem;
  color: #333;
  font-family: 'Arial', sans-serif;
  animation: fadeIn 1s ease-in-out;
}

.categories-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  justify-content: center;
  align-items: stretch;
  padding: 1rem;
  animation: slideIn 1s ease-in-out;
}

.category-card {
  background: white;
  border-radius: 12px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s, box-shadow 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
}

.category-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}

.image-overlay {
  position: relative;
}

.category-image {
  width: 100%;
  height: 700px; /* Set a fixed height for all images */
  object-fit: cover; /* Ensures the image covers the area without distortion */
  transition: opacity 0.3s;
}

.category-card:hover .category-image {
  opacity: 0.7;
}

.category-content {
  padding: 1.5rem;
  text-align: center;
}

.category-title {
  font-size: 2rem;
  margin: 1rem 0 0.5rem;
  color: #333;
  font-family: 'Helvetica', sans-serif;
}

.category-description {
  margin-bottom: 1.5rem;
  color: #666;
  font-family: 'Helvetica', sans-serif;
}

/* Buttons Styling */
.category-buttons {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.category-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  outline: 0;
  border: 0;
  cursor: pointer;
  will-change: box-shadow, transform;
  background: radial-gradient(100% 100% at 100% 0%, #89E5FF 0%, #5468FF 100%);
  box-shadow: 0px 2px 4px rgb(45 35 66 / 40%), 0px 7px 13px -3px rgb(45 35 66 / 30%), inset 0px -3px 0px rgb(58 65 111 / 50%);
  padding: 0 32px;
  border-radius: 6px;
  color: #fff;
  height: 48px;
  font-size: 18px;
  text-shadow: 0 1px 0 rgb(0 0 0 / 40%);
  transition: box-shadow 0.15s ease, transform 0.15s ease;
}

.category-button:hover {
  box-shadow: 0px 4px 8px rgb(45 35 66 / 40%), 0px 7px 13px -3px rgb(45 35 66 / 30%), inset 0px -3px 0px #3c4fe0;
  transform: translateY(-2px);
}

.category-button:active {
  box-shadow: inset 0px 3px 7px #3c4fe0;
  transform: translateY(2px);
}
.category-button.reserve {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  outline: 0;
  border: 0;
  cursor: pointer;
  transition: box-shadow 0.15s ease, transform 0.15s ease;
  will-change: box-shadow, transform;
  background: #FCFCFD;
  box-shadow: 0px 2px 4px rgb(45 35 66 / 40%), 0px 7px 13px -3px rgb(45 35 66 / 30%), inset 0px -3px 0px #d6d6e7;
  height: 48px;
  padding: 0 32px;
  font-size: 18px;
  border-radius: 6px;
  color: #36395a;
}

.category-button.reserve:hover {
  box-shadow: 0px 4px 8px rgb(45 35 66 / 40%), 0px 7px 13px -3px rgb(45 35 66 / 30%), inset 0px -3px 0px #d6d6e7;
  transform: translateY(-2px);
}

.category-button.reserve:active {
  box-shadow: inset 0px 3px 7px #d6d6e7;
  transform: translateY(2px);
}



/* Keyframes for Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Ensure all categories are evenly distributed */
@media (min-width: 1200px) {
  .categories-container {
    grid-template-columns: repeat(3, 1fr);
  }
}
