@import url(//fonts.googleapis.com/css?family=Montserrat:300,400,500);

.service-12 {
  font-family: "Montserrat", sans-serif;
  color: #8d97ad;
  font-weight: 300;
  overflow-x: hidden; /* Prevent horizontal overflow */
}

.service-12 h1, .service-12 h2, .service-12 h3, .service-12 h4, .service-12 h5, .service-12 h6 {
  color: #3e4555;
}

.service-12 h6 {
  line-height: 22px;
  font-size: 18px;
}

.service-12 .font-weight-medium {
  font-weight: 500;
}

.service-12 .badge {
  line-height: 14px;
}

.service-12 .badge-info {
  background: #188ef4;
}

.service-12 .subtitle {
  color: #8d97ad;
  line-height: 24px;
}

.service-12 .card.card-shadow {
  -webkit-box-shadow: 0px 0px 30px rgba(115, 128, 157, 0.1);
  box-shadow: 0px 0px 30px rgba(115, 128, 157, 0.1);
}

.service-12 .img-shadow {
  -webkit-box-shadow: 0px 0px 30px rgba(115, 128, 157, 0.3);
  box-shadow: 0px 0px 30px rgba(115, 128, 157, 0.3);
}

.service-12 .wrap-service12 .img-hover {
  -webkit-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  transition: 0.2s ease-in;
}

.service-12 .wrap-service12 .img-hover:hover {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.service-12 .wrap-service12 .uneven-box {
  margin-top: 100px;
}

.service-12 .btn-info-gradiant {
  background: #188ef4;
  background: -webkit-linear-gradient(legacy-direction(to right), #188ef4 0%, #316ce8 100%);
  background: -webkit-gradient(linear, left top, right top, from(#188ef4), to(#316ce8));
  background: -webkit-linear-gradient(left, #188ef4 0%, #316ce8 100%);
  background: -o-linear-gradient(left, #188ef4 0%, #316ce8 100%);
  background: linear-gradient(to right, #188ef4 0%, #316ce8 100%);
}

.service-12 .btn-info-gradiant:hover {
  background: #316ce8;
  background: -webkit-linear-gradient(legacy-direction(to right), #316ce8 0%, #188ef4 100%);
  background: -webkit-gradient(linear, left top, right top, from(#316ce8), to(#188ef4));
  background: -webkit-linear-gradient(left, #316ce8 0%, #188ef4 100%);
  background: -o-linear-gradient(left, #316ce8 0%, #188ef4 100%);
  background: linear-gradient(to right, #316ce8 0%, #188ef4 100%);
}

.service-12 .btn-md {
  padding: 15px 45px;
  font-size: 16px;
}

@media (max-width: 767px) {
  .service-12 .wrap-service12 .uneven-box {
    margin-top: 0px;
  }
}

@import url(//fonts.googleapis.com/css?family=Montserrat:300,500);

.service-26 {
  font-family: "Montserrat", sans-serif;
  color: #8d97ad;
  font-weight: 300;
  overflow-x: hidden; /* Prevent horizontal overflow */
}

.service-26 h1,
.service-26 h2,
.service-26 h3,
.service-26 h4,
.service-26 h5,
.service-26 h6 {
  color: #3e4555;
}

.service-26 .font-weight-medium {
  font-weight: 500;
}

.service-26 .badge {
  line-height: 14px;
}

.service-26 .badge-info {
  background: #188ef4;
}

.service-26 .wrap-service-26 .max-box {
  max-width: 580px;
  margin: 0 auto;
}

.service-26 a {
  text-decoration: none;
}

.service-26 .linking {
  color: #3e4555;
}

.service-26 .linking:hover {
  color: #316ce8;
}

/* Responsive styling for service-26 */
@media (max-width: 767px) {
  .service-26 .wrap-service-26 {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    width: 100%; /* Ensure wrap-service-26 takes full width */
    box-sizing: border-box;
  }

  .service-26 .col-md-7,
  .service-26 .col-md-5,
  .service-26 .col-md-6 {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
  }

  .service-26 img {
    width: 100%;
    height: auto;
    box-sizing: border-box;
  }

  .service-26 .max-box {
    margin: 0 auto;
    text-align: center;
    width: 100%;
    box-sizing: border-box;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .service-26 .col-md-7,
  .service-26 .col-md-5,
  .service-26 .col-md-6 {
    padding: 15px;
    box-sizing: border-box;
  }

  .service-26 .max-box {
    margin: 0 auto;
    width: 100%;
    box-sizing: border-box;
  }
}

/* Additional responsive adjustments */
@media (max-width: 575px) {
  .service-26 .wrap-service-26 {
    padding: 20px;
    width: 100%; /* Ensure wrap-service-26 takes full width */
    box-sizing: border-box;
  }
  
  .service-26 .col-md-7,
  .service-26 .col-md-5,
  .service-26 .col-md-6 {
    padding: 5px;
    width: 100%; /* Ensure columns take full width */
    box-sizing: border-box;
  }
}

@import url(//fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700,800);

.pricing8 {
  font-family: "Montserrat", sans-serif;
  color: #8d97ad;
  font-weight: 300;
}

.pricing8 h1,
.pricing8 h2,
.pricing8 h3,
.pricing8 h4,
.pricing8 h5,
.pricing8 h6 {
  color: #3e4555;
}

.pricing8 h5 {
    line-height: 22px;
    font-size: 18px;
}

.pricing8 .subtitle {
  color: #8d97ad;
  line-height: 24px;
}

.pricing8 .display-5 {
  font-size: 3rem;
}

.pricing8 .font-14 {
  font-size: 14px;
}

.pricing8 .pricing-box sup {
  top: -20px;
  font-size: 16px;
}

.pricing8 .btn-info-gradiant {
  background: #188ef4;
  background: -webkit-linear-gradient(legacy-direction(to right), #188ef4 0%, #316ce8 100%);
  background: -webkit-gradient(linear, left top, right top, from(#188ef4), to(#316ce8));
  background: -webkit-linear-gradient(left, #188ef4 0%, #316ce8 100%);
  background: -o-linear-gradient(left, #188ef4 0%, #316ce8 100%);
  background: linear-gradient(to right, #188ef4 0%, #316ce8 100%);
}

.pricing8 .btn-info-gradiant:hover {
  background: #316ce8;
  background: -webkit-linear-gradient(legacy-direction(to right), #316ce8 0%, #188ef4 100%);
  background: -webkit-gradient(linear, left top, right top, from(#316ce8), to(#188ef4));
  background: -webkit-linear-gradient(left, #316ce8 0%, #188ef4 100%);
  background: -o-linear-gradient(left, #316ce8 0%, #188ef4 100%);
  background: linear-gradient(to right, #316ce8 0%, #188ef4 100%);
}

.pricing8 .btn-danger-gradiant {
  background: #ff4d7e;
  background: -webkit-linear-gradient(legacy-direction(to right), #ff4d7e 0%, #ff6a5b 100%);
  background: -webkit-gradient(linear, left top, right top, from(#ff4d7e), to(#ff6a5b));
  background: -webkit-linear-gradient(left, #ff4d7e 0%, #ff6a5b 100%);
  background: -o-linear-gradient(left, #ff4d7e 0%, #ff6a5b 100%);
  background: linear-gradient(to right, #ff4d7e 0%, #ff6a5b 100%);
}

.pricing8 .btn-danger-gradiant:hover {
  background: #ff6a5b;
  background: -webkit-linear-gradient(legacy-direction(to right), #ff6a5b 0%, #ff4d7e 100%);
  background: -webkit-gradient(linear, left top, right top, from(#ff6a5b), to(#ff4d7e));
  background: -webkit-linear-gradient(left, #ff6a5b 0%, #ff4d7e 100%);
  background: -o-linear-gradient(left, #ff6a5b 0%, #ff4d7e 100%);
  background: linear-gradient(to right, #ff6a5b 0%, #ff4d7e 100%);
}

@import url('https://fonts.googleapis.com/css?family=Montserrat:400,800|Poppins&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
}

.cards {
  max-width: 1100px;
  margin: 0 auto;
  text-align: center;
  padding: 30px;
}

.cards h2.header {
  font-size: 40px;
  margin: 0 0 30px 0;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.services {
  display: flex;
  align-items: center;
}

@media (max-width: 900px) {
  .services {
    flex-direction: column;
  }
}

.content {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  margin: 20px;
  padding: 20px;
  border: 2px solid black;
  border-radius: 4px;
  transition: all .3s ease;
}

.content .fab {
  font-size: 70px;
  margin: 16px 0;
}

.content > * {
  flex: 1 1 100%;
}

.content:hover {
  color: white;
}

.content:hover a {
  border-color: white;
  background: white;
}

.content-1:hover {
  border-color: #1DA1F2;
  background: #1DA1F2;
}

.content-1:hover a {
  color: #1DA1F2;
}

.content-2:hover {
  border-color: #E1306C;
  background: #E1306C;
}

.content-2:hover a {
  color: #E1306C;
}

.content-3:hover {
  border-color: #ff0000;
  background: #ff0000;
}

.content-3:hover a {
  color: #ff0000;
}

.content h2 {
  font-size: 30px;
  margin: 16px 0;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.content p {
  font-size: 17px;
  font-family: 'Poppins', sans-serif;
}

.content a {
  margin: 22px 0;
  background: black;
  color: white;
  text-decoration: none;
  text-transform: uppercase;
  border: 1px solid black;
  padding: 15px 0;
  border-radius: 25px;
  transition: .3s ease;
}

.content a:hover {
  border-radius: 4px;
}

/* Additional responsive styling */
@media (max-width: 600px) {
  .cards h2.header {
    font-size: 30px;
  }

  .content {
    margin: 10px;
    padding: 15px;
  }

  .content h2 {
    font-size: 24px;
  }

  .content p {
    font-size: 14px;
  }

  .content a {
    padding: 10px 0;
    font-size: 14px;
  }

  .content .fab {
    font-size: 50px;
  }
}
