@import url(//fonts.googleapis.com/css?family=Montserrat:300,400,500);

.pricing4 {
  font-family: "Montserrat", sans-serif;
  color: #8d97ad;
  font-weight: 300;
}

.pricing4 h1,
.pricing4 h2,
.pricing4 h3,
.pricing4 h4,
.pricing4 h5,
.pricing4 h6 {
  color: #3e4555;
}

.pricing4 .font-weight-medium {
  font-weight: 500;
}

.pricing4 .bg-light {
  background-color: #f4f8fa !important;
}

.pricing4 .subtitle {
  color: #8d97ad;
  line-height: 24px;
}

.pricing4 .font-14 {
  font-size: 14px;
}

.pricing4 .font-13 {
  font-size: 13px;
}

.pricing4 .card.card-shadow {
  -webkit-box-shadow: 0px 0px 30px rgba(115, 128, 157, 0.1);
  box-shadow: 0px 0px 30px rgba(115, 128, 157, 0.1);
}

.pricing4 .text-success {
  color: #2cdd9b !important;
}

.pricing4 .price small {
  color: #8d97ad;
  font-size: 16px;
}

.pricing4 h5 {
  line-height: 22px;
  font-size: 18px;
}

.pricing4 .btn-danger-gradiant {
  background: #ff4d7e;
  background: -webkit-linear-gradient(legacy-direction(to right), #ff4d7e 0%, #ff6a5b 100%);
  background: -webkit-gradient(linear, left top, right top, from(#ff4d7e), to(#ff6a5b));
  background: -webkit-linear-gradient(left, #ff4d7e 0%, #ff6a5b 100%);
  background: -o-linear-gradient(left, #ff4d7e 0%, #ff6a5b 100%);
  background: linear-gradient(to right, #ff4d7e 0%, #ff6a5b 100%);
}

.pricing4 .btn-danger-gradiant:hover {
  background: #ff6a5b;
  background: -webkit-linear-gradient(legacy-direction(to right), #ff6a5b 0%, #ff4d7e 100%);
  background: -webkit-gradient(linear, left top, right top, from(#ff6a5b), to(#ff4d7e));
  background: -webkit-linear-gradient(left, #ff6a5b 0%, #ff4d7e 100%);
  background: -o-linear-gradient(left, #ff6a5b 0%, #ff4d7e 100%);
  background: linear-gradient(to right, #ff6a5b 0%, #ff4d7e 100%);
}

.pricing4 .btn-md {
  padding: 10px 25px;
  font-size: 16px;
}

/* Ensure all images are the same size */
.pricing4 .card-img-top {
  width: 100%;
  height: 200px; /* Set the desired height */
  object-fit: cover; /* Maintain aspect ratio and cover the area */
}
