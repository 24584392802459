/* General Styling */
body {
  font-family: 'Helvetica', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f2f2f2;
  color: #333;
}

.theme-title {
  font-size: 6rem; /* Reduced font size for mobile */
  text-align: center;
  margin-bottom: 1rem;
}

@media (min-width: 960px) {
  .theme-title {
    font-size: 9rem; /* Increased font size for larger screens */
  }
}

.category-page {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hero-section {
  position: relative;
  width: 100%;
  height: 60vh;
  overflow: hidden;
}

.hero-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  text-align: center;
  padding: 1rem;
}

.hero-title {
  font-size: 2rem; /* Reduced font size for mobile */
  margin: 0;
}

@media (min-width: 960px) {
  .hero-title {
    font-size: 3rem; /* Increased font size for larger screens */
  }
}

.hero-description {
  font-size: 1rem; /* Reduced font size for mobile */
  margin: 0.5rem 0;
}

@media (min-width: 960px) {
  .hero-description {
    font-size: 1.5rem; /* Increased font size for larger screens */
  }
}

.content-section {
  padding: 1rem; /* Reduced padding for mobile */
  max-width: 90%; /* Adjusted max-width for better mobile view */
  text-align: center;
}

@media (min-width: 960px) {
  .content-section {
    padding: 2rem; /* Increased padding for larger screens */
    max-width: 800px; /* Original max-width for larger screens */
  }
}

.text-content {
  margin-bottom: 40px;
}

.theme-section {
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 16px;
  margin: 16px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: auto;
}

.theme-section p,
.theme-section ul,
.theme-section strong {
  margin-bottom: 1rem;
}

.theme-section ul {
  padding-left: 1.5rem;
}

.theme-section li {
  margin-bottom: 0.5rem;
}

.theme-image {
  width: 100%;
  height: auto;
  max-height: 400px; /* Reduced max-height for mobile */
  object-fit: cover;
  border-radius: 8px;
  margin: 16px 0;
}

@media (min-width: 960px) {
  .theme-image {
    max-height: 700px; /* Original max-height for larger screens */
  }
}

/* Ensure alternating layout for text and images */
@media (min-width: 960px) {
  .MuiGrid-container:nth-of-type(even) .theme-image {
    order: 1;
  }
  .MuiGrid-container:nth-of-type(even) .theme-section {
    order: 2;
  }
}
