@import url(//fonts.googleapis.com/css?family=Montserrat:300,500);

.team4 {
  font-family: "Montserrat", sans-serif;
  color: #080808;
  font-weight: 300;
}

.team4 h1, .team4 h2, .team4 h3, .team4 h4, .team4 h5, .team4 h6 {
  color: #3e4555;
}

.team4 .font-weight-medium {
  font-weight: 500;
}

.team4 h5 {
  line-height: 22px;
  font-size: 18px;
}

.team4 .subtitle {
  color: #8d97ad;
  line-height: 24px;
  font-size: 13px;
}

.team4 ul li a {
  color: #8d97ad;
  padding-right: 15px;
  -webkit-transition: 0.1s ease-in;
  -o-transition: 0.1s ease-in;
  transition: 0.1s ease-in;
}

.team4 ul li a:hover {
  -webkit-transform: translate3d(0px, -5px, 0px);
  transform: translate3d(0px, -5px, 0px);
  color: #316ce8;
}

.team4 .team-member img {
  width: 300px; /* Set the desired width */
  height: 300px; /* Set the desired height */
  object-fit: cover; /* Maintain aspect ratio and fill */
}
