@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

.schedule-container {
  background-color: #f4f4f4; /* Set the schedule page background to light grey */
  min-height: 100vh;
  padding: 20px;
}

.planning-header {
  text-align: center;
  margin-bottom: 20px;
}

.planning-header h1 {
  text-align: center;
  font-family: 'Impact', fantasy;
  font-size: 36px;
  color: black;
  margin-bottom: 10px; /* Add margin to separate the filter */
}

.filter {
  text-align: center;
  display: flex;
  justify-content: center; /* Center the filter */
  align-items: center;
  margin-bottom: 20px; /* Add margin to separate the filter from the schedule */
}

.filter label {
  margin-right: 10px;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
}

.filter select {
  padding: 5px 10px;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
}

.schedule {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  flex-wrap: wrap;
}

.schedule-day {
  border-radius: 10px;
  padding: 10px;
  width: calc(14.28% - 20px); /* Width for larger screens */
  margin: 5px;
  color: black;
}

.day-header {
  font-size: 18px;
  font-weight: 700; /* Use 700 for bold */
  margin-bottom: 10px;
  text-align: center;
  font-family: 'Impact', fantasy;
  color: black;
}

.schedule-slot {
  background-color: white; /* Set the background color of schedule slots to white */
  border-radius: 10px;
  margin-bottom: 12px;
  padding: 20px;
  text-align: center;
  font-family: 'Roboto', sans-serif; /* Use Roboto font family */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 250px; /* Set a fixed height */
  width: 100%; /* Make width 100% of parent container */
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.time {
  font-family: 'Impact', fantasy;
  font-size: 24px; /* Increase the font size */
  font-weight: 200; /* Use 800 for extra bold */
  margin-bottom: 10px; /* Adjust the margin */
  color: black;
}

.details {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.details .duration {
  font-size: 14px;
  font-weight: 400;
  margin: 0;
  display: inline; /* Ensure duration is displayed inline */
}

.details .course {
  font-family: 'Impact', fantasy;
  font-size: 19px;
  font-weight: 550;
  margin-bottom: 5px;
  color: black;
}

.details .instructor {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 10px;
}

.reserve-button {
  align-self: center; /* Center the button */
  display: inline-block;
  outline: 0;
  border: 0;
  cursor: pointer;
  background: #000000;
  color: #FFFFFF;
  border-radius: 8px;
  padding: 14px 24px 16px;
  font-size: 18px;
  font-weight: 700;
  line-height: 1;
  transition: transform 200ms, background 200ms;
}

.reserve-button:hover {
  transform: translateY(-2px);
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .schedule {
    flex-direction: column;
    padding: 10px;
  }

  .schedule-day {
    width: 100%; /* Full width for mobile devices */
    margin: 5px 0;
  }

  .schedule-slot {
    height: auto; /* Remove fixed height for mobile */
    padding: 15px;
  }

  .time {
    font-size: 20px;
  }

  .details .course {
    font-size: 16px;
  }

  .details .instructor, .details .duration {
    font-size: 12px;
  }

  .reserve-button {
    font-size: 16px;
    padding: 12px 20px 14px;
  }
}
