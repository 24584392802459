@import url(//fonts.googleapis.com/css?family=Montserrat:400,500,700);

.static-slider7 {
  font-family: "Montserrat", sans-serif;
  color: #8d97ad;
  font-weight: 300;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; /* Center the image */
  padding: 12% 0;
  height: 100vh; /* Full viewport height */
  width: 100vw; /* Full viewport width */
  position: relative; /* For positioning children if needed */
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 1vh;
}

.content-container {
  width: 250px;
  position: center;
  margin-left: 50px;
  margin-top:100px;
  background: rgba(219, 219, 219, 0.5); /* Semi-transparent background */
  padding: 20px;
  border-radius: 10px;
  align-items: Bottom;
  position: bottom;
}

.hero-header {
  font-size: 48px;
  font-weight: 700;
  color: #ffffff; /* Ensures the text is white */
  margin-bottom: 20px;
}

.hero-text {
  font-size: 24px;
  color: #ffffff; /* Ensures the text is white */
  margin-bottom: 20px;
}

.home-btn {
  display: inline-block;
  outline: 0;
  border: 0;
  cursor: pointer;
  background: #ff4d7e;
  color: #ffffff;
  border-radius: 8px;
  padding: 14px 24px;
  font-size: 18px;
  font-weight: 700;
  line-height: 1;
  transition: transform 200ms, background 200ms;
}

.home-btn:hover {
  transform: translateY(-2px);
  background: #ff6a5b;
}

@media (max-width: 767px) {
  .static-slider7 {
    height: 50vh; /* Reduced height for mobile */
    background-size: cover;
    background-position: center center;
    padding: 8% 0; /* Reduced padding for smaller screens */
  }

  .content-container {
    max-width: 90%; /* Take up more width on mobile for better readability */
    margin-left: 20px; /* Reduced margin for smaller screens */
    padding: 15px; /* Adjusted padding for smaller screens */
  }

  .hero-header {
    font-size: 30px; /* Smaller header size for mobile */
    line-height: 36px; /* Adjust line height for better readability */
  }

  .hero-text {
    font-size: 16px; /* Smaller text size */
    line-height: 22px; /* Adjust line height for better readability */
  }

  .home-btn {
    padding: 10px 20px; /* Adjust button padding */
    font-size: 14px; /* Smaller font size for the button */
  }
}

.typewrite > .wrap {
  border-right: 0.08em solid transparent;
}
